<template>
  <section :style="{ width: chatboxWidth + '%' }" class="follow-up-question">
    <label for="questionInput" class="visually-hidden">
      Enter your follow-up question here
    </label>

    <input
      @keydown.enter="submitQuestion"
      id="questionInput"
      type="text"
      class="question-input"
      placeholder="Enter your follow-up question here."
      aria-label="Enter your follow-up question here"
      v-model="questionInput"
    />
    <button
      type="button"
      class="submit-button"
      aria-label="Submit question"
      @click="submitQuestion"
    >
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/5dd99269432a0d1e6de16400dc12657d6d5258d26c0474f2f212ce648206cceb?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
        class="submit-icon"
        alt="Submit"
      />
    </button>
  </section>
</template>

<script>
export default {
  data() {
    return {
      questionInput: "",
      chatboxWidth: 400,
    };
  },
  methods: {
    submitQuestion() {
      this.$emit("chat-submit", this.questionInput);
      this.questionInput = "";
    },

    updateChatboxWidth() {
      const sidebar = document.getElementById("SidebarLeft");

      if (sidebar) {
        setTimeout(() => {
          const sidebarWidthPercentage =
            (sidebar.offsetWidth / window.innerWidth) * 100;

          const chatboxWidthPercentage = Math.max(
            0,
            100 - sidebarWidthPercentage - 3
          );

          this.chatboxWidth = chatboxWidthPercentage;
        }, 250);
      }
    },
  },

  mounted() {
    const element = document.getElementById("SidebarLeft");

    this.observer = new MutationObserver(() => {
      this.updateChatboxWidth();
    });

    if (element) {
      this.observer.observe(element, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      this.updateChatboxWidth();
    }

    this.updateChatboxWidth();
  },

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style scoped>
.follow-up-question {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid var(--Primary-Blue, #0e4485);
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 10px 0 rgba(14, 68, 133, 0.6);
  padding: 12px 20px;
}

.question-input {
  flex: 1;
  min-width: 240px;
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  padding: 10px 16px 10px 0;
  font: 400 16px/28px Poppins, sans-serif;
  border: none;
  background: transparent;
}

.question-input:focus {
  color: black;
}

.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 6px;
}

.submit-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 991px) {
  .question-input {
    max-width: 100%;
  }
}
</style>
